@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: "Inter", sans-serif;
  }
}
@layer base {
  @font-face {
    font-family: 'myfont';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./Dancing_Script/static/DancingScript-Regular.ttf") format('woff2');
  }
}
@layer components {
  input[type="checkbox"]:checked ~ label span svg {
    @apply inline-flex;
  }

  .sticky .header-logo {
    @apply py-5 lg:py-2;
  }

  .sticky .menu-scroll.active {
    @apply opacity-70;
  }

  input#togglePlan:checked ~ .dot {
    @apply translate-x-full;
  }

  input#checkboxLabel:checked ~ .box span {
    @apply opacity-100;
  }
}
.header {
  transition: all 0.3s ease;
}

.header.shrunk {
  padding: 10px 0;
  height: 100px;
}

.header .header-logo {
  transition: all 0.3s ease;
}

.header.shrunk .header-logo {
  /* width: 80px; */
}

.singlesrve{
  position: relative;
  height: 300px !important;
  border-radius: 10px;
  overflow: hidden;
  transition: all ease 1s;

}
.item{
  position: absolute;
  height: 100%;
  background-position: center;
  background-size: contain;
  width: 100%;
}
.shadow{
  background-color: rgba(0, 0, 0, 0.507);
  position: absolute;
  width: 100%;  border-radius: 10px;
  transition: background-color ease 1s;
  height: 100%;
}
.item-h1{
  /* height: 100% !important; */
  position: absolute;
  width: 100%;
  color: white;
  z-index: 10;
  left: 20px;
  bottom: 10px;
  transition: top ease 1s, bottom ease 1s;

}
.item-p{
  /* height: 100% !important; */
  position: absolute;
  /* width: 100%; */
  color: rgba(255, 255, 255, 0.685);
  z-index: 10;
  left: 20px;
  transition: 1s;
  top: 40%;  transform: rotatex(90deg);
  transition: all ease 1s;

}
.singlesrve:hover .shadow{
  background-color: rgba(0, 0, 0, 0.705);


}
/* .singlesrve:hover .item-h1{
  top: 50px;


} */
.singlesrve:hover .item-p{
  transform: rotateY(0deg)


}
.swiper-pagination-bullet-active {
  background-color: #149D80 !important;
}